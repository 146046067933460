import React, { Component } from "react";

import Header from "./Header";
import Row from "./Row";
import { countBy } from "lodash";
import styled from "styled-components";

const Container = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
`;

const Constants = {
  selectionsPerWeek: 4
};

class Table extends Component {
  constructor(props) {
    super(props);

    this.state = { selections: props.selections };
  }

  onSelection = (team, game, isSelected) => {
    var selections = this.state.selections;
    if (isSelected) {
      selections[team] = game.week;
    } else {
      delete selections[team];
    }

    this.setState({ selections });

    this.props.onSelectionStateChange(selections);
  };

  gamesForTeam = team => this.props.teams[team];

  selectionForTeam = team => this.state.selections[team];

  render() {
    const weeks = this.props.weeks,
      teams = Object.keys(this.props.teams),
      selectionsForWeek = countBy(Object.values(this.state.selections)),
      unselectableWeeks = Object.keys(selectionsForWeek)
        .filter(week => selectionsForWeek[week] >= Constants.selectionsPerWeek)
        .map(week => Number(week));

    return (
      <Container>
        <tbody>
          <Header weeks={weeks} />
          {teams.map(team => (
            <Row
              key={team}
              team={team}
              games={this.gamesForTeam(team)}
              selection={this.selectionForTeam(team)}
              unselectableWeeks={unselectableWeeks}
              weeks={weeks}
              onSelection={this.onSelection}
            />
          ))}
        </tbody>
      </Container>
    );
  }
}

export default Table;
