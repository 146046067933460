import React from "react";
import styled from "styled-components";

const Container = styled.tr`
  font-weight: bolder;
`;

const Cell = styled.th`
  padding: 8px;
  background-color: #181818;
`;

const Header = props => {
  return (
    <Container>
      <Cell />
      {props.weeks.map(week => (
        <Cell key={week}>{week}</Cell>
      ))}
    </Container>
  );
};

export default Header;
