import React from "react";
import styled from "styled-components";

const Cell = styled.td`
  padding: 5px;
  background-color: #181818;
`;

const Logo = styled.img`
  height: 24px;
  width: 24px;
`;

const Team = props => {
  const logoFromTeam = team =>
    `https://secure.espn.com/combiner/i?img=/i/teamlogos/nfl/500/scoreboard/${team}.png&w=56&h=56`;

  return (
    <Cell>
      <Logo src={logoFromTeam(props.team)} />
    </Cell>
  );
};

export default Team;
