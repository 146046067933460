import React from "react";
import aaugh from "./images/aaugh.png";
import styled from "styled-components";

const Container = styled.div`
  img {
    max-width: 75%;
  }
`;

const InitialLoadError = _ => {
  return (
    <Container>
      <img src={aaugh} alt="An error occurred, and we couldn’t load the schedule" />
    </Container>
  );
};

export default InitialLoadError;
