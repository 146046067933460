import { BrowserRouter, Route } from "react-router-dom";
import React, { Component } from "react";

import Gridiron from "./Gridiron";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import styled from "styled-components";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
});

// https://github.com/firebase/firebaseui-web#configuration
const firebaseUIConfig = {
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false
    }
  ]
};

const AuthContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

class App extends Component {
  constructor(props) {
    super(props);

    // https://firebase.google.com/docs/auth/web/auth-state-persistence?authuser=0
    this.state = { isSignedIn: false };
  }

  render() {
    if (this.state.isSignedIn) {
      return (
        <BrowserRouter>
          <Route path="/" component={Gridiron} />
        </BrowserRouter>
      );
    } else {
      return (
        <AuthContainer>
          <StyledFirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={firebase.auth()} />
        </AuthContainer>
      );
    }
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => this.setState({ isSignedIn: !!user }));
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }
}

export default App;
