import { css } from "styled-components";

const Constants = {
  desktopWidth: 1125
};

export const Media = {
  mobile: (strings, ...interpolations) =>
    css`
      @media (max-width: ${Constants.desktopWidth - 1}px) {
        ${css(strings, ...interpolations)};
      }
    `,

  desktop: (strings, ...interpolations) =>
    css`
      @media (min-width: ${Constants.desktopWidth}px) {
        ${css(strings, ...interpolations)};
      }
    `
};
