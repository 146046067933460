import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

import React, { Component } from "react";

import Alert from "react-s-alert";
import ClosedSign from "./ClosedSign";
import Colors from "./utils/Colors";
import { GridLoader } from "halogenium";
import InitialLoadError from "./InitialLoadError";
import Table from "./table/Table";
import axios from "axios";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: white;
`;

const TableOrLoaderOrClosedSignOrError = props => {
  if (props.error) {
    return <InitialLoadError />;
  } else if (props.loading) {
    return <GridLoader color={Colors.blue} />;
  } else if (!props.inSeason) {
    return <ClosedSign />;
  } else {
    return <Table {...props} />;
  }
};

class Gridiron extends Component {
  constructor(props) {
    super(props);

    this.state = { weeks: [], teams: {}, selections: [], loading: true };

    axios
      .get(`${process.env.REACT_APP_API_URL}/schedule${props.location.search}`)
      .then(response => response.data)
      .then(data => {
        this.setState({
          inSeason: data.inSeason,
          weeks: data.weeks,
          teams: data.teams,
          selections: data.selections,
          loading: false,
          error: false
        });
      })
      .catch(_ => {
        this.setState({
          error: true
        });
      });
  }

  onSelectionStateChange = selections => {
    axios.post(`${process.env.REACT_APP_API_URL}/selections${this.props.location.search}`, selections).catch(_ => {
      Alert.error(
        "<p>Oh no!</p><br><p>An error occurred, please check the server.</p><br><p>You should probably also reload this page, to make sure that you’re seeing the most accurate data.</p>"
      );
    });
  };

  render() {
    return (
      <Container>
        <TableOrLoaderOrClosedSignOrError {...this.state} onSelectionStateChange={this.onSelectionStateChange} />
        <Alert stack={true} html={true} effect="slide" position="top-right" />
      </Container>
    );
  }
}

export default Gridiron;
