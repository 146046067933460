import React from "react";
import closed from "./images/closed.png";
import styled from "styled-components";

const Container = styled.div`
  img {
    max-width: 75%;
  }
`;

const ClosedSign = _ => {
  return (
    <Container>
      <img src={closed} alt="The season’s over! Try again next year" />
    </Container>
  );
};

export default ClosedSign;
