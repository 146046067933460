import { isEqual, isUndefined } from "lodash";

import Game from "./Game";
import React from "react";
import Team from "./Team";
import styled from "styled-components";

const Bye = styled.td``;

const Row = props => {
  const hasSelection = !isUndefined(props.selection);

  return (
    <tr>
      <Team team={props.team} />

      {props.weeks.map(week => {
        const game = props.games[week],
          hasGame = !isUndefined(game),
          selected = isEqual(props.selection, week),
          unselectableWeek = props.unselectableWeeks.includes(week),
          selectable = selected || !(hasSelection || unselectableWeek);

        if (hasGame) {
          return (
            <Game
              selected={selected}
              selectable={selectable}
              key={game.id}
              game={game}
              team={props.team}
              onSelection={props.onSelection}
            />
          );
        } else {
          return <Bye key="bye" />;
        }
      })}
    </tr>
  );
};

export default Row;
