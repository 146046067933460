import Colors from "../utils/Colors";
import { Media } from "../utils/Media";
import React from "react";
import interpolate from "color-interpolate";
import styled from "styled-components";

const Cell = styled.td.attrs(props => ({
  style: {
    backgroundColor: props.selected ? Colors.green : backgroundColor(props.winPercentage),
    color: props.selected ? textColor(props.winPercentage) : Colors.black,
    cursor: props.selectable ? "pointer" : "not-allowed",
    opacity: props.selectable ? 1 : 0.2
  }
}))`
  position: relative;
  font-weight: bold;
`;

const Percentage = styled.div`
  font-size: 12px;
  font-weight: normal;

  ${Media.desktop`
    position: absolute;
    right: 4px;
    top: 4px;
  `};
`;

const Away = styled.div`
  ${Media.desktop`
    position: absolute;
    left: 8px;
  `};

  ${Media.mobile`
    display: inline;
  `};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 14px;
  font-weight: normal;
  opacity: 0.8;
`;

const isTeam1 = (game, team) => game.team1 === team;

const isAway = (game, team) => !isTeam1(game, team);

const opponent = (game, team) => (isTeam1(game, team) ? game.team2 : game.team1);

const winPercentage = (game, team) => (isTeam1(game, team) ? game.prob1 : game.prob2);

const backgroundColor = interpolate([Colors.red, Colors.blue]);

const textColor = interpolate([Colors.red, Colors.black]);

const Game = props => {
  const percentage = winPercentage(props.game, props.team);

  const percentageDisplayString = `${(percentage * 100).toFixed(0)}%`;

  return (
    <Cell
      winPercentage={percentage}
      selected={props.selected}
      selectable={props.selectable}
      onClick={() => {
        if (props.selectable) {
          props.onSelection(props.team, props.game, !props.selected);
        }
      }}
    >
      {isAway(props.game, props.team) && <Away>@</Away>}
      {opponent(props.game, props.team)}
      <Percentage>{percentageDisplayString}</Percentage>
    </Cell>
  );
};

export default Game;
